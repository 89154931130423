<template>
  <b-list-group-item v-if="value">
    <div class="font-weight-bold text-primary">
      {{ title }}
    </div>
    <div class="font-weight-light">
      {{ value }}
    </div>
  </b-list-group-item>
</template>
<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ListItem',
  components: {
    BListGroupItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
  },
}
</script>

<style scoped>

</style>
