<template>
  <b-form-group
    label="Teslim Tarihi"
    label-for="deadline"
  >
    <b-form-datepicker
      id="deadline"
      v-model="dataItem.deadline"
      placeholder="Teslim Tarihi"
      v-bind="{labelNoDateSelected: 'Teslim Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
      locale="tr"
      start-weekday="1"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormDatepicker } from 'bootstrap-vue'

export default {
  name: 'DeadLine',
  components: {
    BFormGroup,
    BFormDatepicker,
  },
  computed: {
    dataItem() {
      return this.$store.getters['taskLines/dataItem']
    },
  },
}
</script>
