<template>
  <b-card>
    <b-list-group>
      <b-list-group-item>
        <b-form-group label="Durum">
          <v-select
            v-model="dataItem.id_task_statuses"
            :options="taskStatuses"
            label="title"
            :reduce="item => item.id"
            placeholder="Durum"
          />
        </b-form-group>
        <b-button
          variant="primary"
          block
          @click="submitForm"
        >
          <FeatherIcon icon="SaveIcon" />
          Güncelle
        </b-button>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Müşteri
        </div>
        <div>{{ dataItem.customer }}</div>
        <div class="text-warning font-small-4">
          {{ dataItem.project }}
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Başlangıç - Teslim Tarihi
        </div>
        <div>
          <span>
            {{ dataItem.sdate !== null? moment(dataItem.sdate).format('DD.MM.YYYY') : 'Belirtilmemiş' }}
          </span>
          -
          <span>
            {{ dataItem.dead_line !== null? moment(dataItem.dead_line).format('DD.MM.YYYY') : 'Belirtilmemiş' }}
          </span>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Görev Adı
        </div>
        <div>{{ dataItem.title }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.text">
        <div class="font-weight-bold text-primary">
          Açıklama
        </div>
        <div v-html="dataItem.text" />
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Toplam Süre
        </div>
        <div class="text-danger">
          {{ formatDuration(dataItem.total_duration) }}
          <!--          {{ moment.utc(moment.duration(dataItem.total_duration).asMilliseconds()).format('HH:mm:ss') }}-->
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Görev Tipi
        </div>
        <div>{{ dataItem.task_type }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Görev Durumu
        </div>
        <div>{{ dataItem.task_status }}</div>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Öncelik
        </div>
        <div>{{ dataItem.priority }}</div>
      </b-list-group-item>
      <b-list-group-item v-if="dataItem.task_users.length">
        <div class="font-weight-bold text-primary">
          Ekip
        </div>
        <b-badge
          v-for="user in dataItem.task_users"
          :key="user.id"
          variant="light-warning"
          class="mr-1"
        >
          {{ user.assigned }}
        </b-badge>
      </b-list-group-item>
      <b-list-group-item>
        <div class="font-weight-bold text-primary">
          Oluşturma
        </div>
        <div>{{ dataItem.username }}</div>
        <div class="font-small-2 text-muted">
          <div>
            {{ moment(dataItem.created).format('DD.MM.YYYY') }} tarihinde oluşturuldu.
          </div>
          <div v-if="dataItem.modified">
            {{ moment(dataItem.modified).format('DD.MM.YYYY') }} tarihinde güncellendi.
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Summary',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BBadge,
    BFormGroup,
    BButton,
    vSelect,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    dataItem() {
      return this.$store.getters['tasks/dataItem']
    },
    taskStatuses() {
      return this.$store.getters['taskStatuses/dataList']
    },
  },
  methods: {
    formatDuration(totalDurationInMilliseconds) {
      const duration = this.moment.duration(totalDurationInMilliseconds)
      // return duration.asHours()
      const hours = Math.floor(duration.asHours())
      const minutes = Math.floor(duration.asMinutes()) % 60
      const seconds = Math.floor(duration.asSeconds()) % 60
      return `${String(hours).padStart(2, '0')} saat ${String(minutes).padStart(2, '0')} dakika ${String(seconds).padStart(2, '0')} saniye`
    },
  },
}
</script>
