<template>
  <div>
    <b-card v-if="dataItem.files.length">
      <b-list-group
        v-for="file in dataItem.files"
        :key="file.id"
      >
        <b-list-group-item>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              {{ file.title }}
            </div>
            <b-button
              variant="flat-success"
              class="btn-icon"
              pill
              :href="baseURL + '/media/task_files/' + file.filename"
              target="_blank"
            >
              <FeatherIcon icon="DownloadIcon" />
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'

export default {
  name: 'FilesCard',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['tasks/dataItem']
    },
  },
}
</script>
