<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <summary-card :submit-form="submitForm" />
      <files-card />
    </b-col>
    <b-col>
      <task-lines :refresh-data="refreshData" />
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import SummaryCard from '@/views/Admin/Tasks/View/SummaryCard.vue'
import FilesCard from '@/views/Admin/Tasks/View/FilesCard.vue'
import TaskLines from '@/views/Admin/Tasks/View/TaskLines.vue'

export default {
  name: 'TaskView',
  components: {
    BRow,
    BCol,
    SummaryCard,
    FilesCard,
    TaskLines,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
    submitForm: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      userStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['tasks/dataItem']
    },
  },
}
</script>
