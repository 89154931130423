<template>
  <b-form-group
    label="Açıklama"
    label-for="text"
  >
    <b-form-textarea
      id="text"
      v-model="dataItem.text"
      placeholder="Açıklama"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'TaskLineText',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    dataItem() {
      return this.$store.getters['taskLines/dataItem']
    },
  },
}
</script>
