<template>
  <div>
    <b-card v-if="dataItem.lines.length">
      <div class="d-flex justify-content-end mb-1">
        <b-form-checkbox
          v-model="hideCompleted"
          class="custom-control-success"
          switch
        >
          Tamamlananları Gizle
        </b-form-checkbox>
      </div>
      <b-list-group>
        <template v-if="hideCompleted">
          <template v-for="(item,index) in dataItem.lines">
            <b-list-group-item
              v-if="item.id_task_statuses !== '5'"
              :key="item.id"
            >
              <task-line-item
                :data-item="item"
                :data-index="index"
                :refresh-data="refreshData"
              />
            </b-list-group-item>
          </template>
        </template>
        <template v-else>
          <template v-for="(item,index) in dataItem.lines">
            <b-list-group-item
              :key="item.id"
            >
              <task-line-item
                :data-item="item"
                :data-index="index"
                :refresh-data="refreshData"
              />
            </b-list-group-item>
          </template>
        </template>
      </b-list-group>
    </b-card>
    <b-card title="Yeni Görev Ekle">
      <task-line :refresh-data="refreshData" />
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BFormCheckbox,
} from 'bootstrap-vue'
import TaskLine from '@/views/Admin/Tasks/elements/TaskLine.vue'
import TaskLineItem from '@/views/Admin/Tasks/View/TaskLineItem.vue'

export default {
  name: 'TaskLines',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BFormCheckbox,
    TaskLine,
    TaskLineItem,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      hideCompleted: true,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['tasks/dataItem']
    },
  },
}
</script>
