<template>
  <b-form-group
    label="Kişi"
    label-for="id_assigned"
  >
    <v-select
      id="id_assigned"
      v-model="dataItem.id_assigned"
      :options="taskData.task_users"
      label="assigned"
      :reduce="item => item.id_assigned"
      placeholder="Seçiniz"
      :disabled="!taskData.task_users.length"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'UserAssigned',
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['taskLines/dataItem']
    },
    taskData() {
      return this.$store.getters['tasks/dataItem']
    },
  },
}
</script>
