<template>
  <b-row class="d-flex align-items-center">
    <b-col
      v-if="['1','2','3'].includes(dataItem.id_task_statuses)"
      cols="12"
      md="auto"
    >
      <b-button
        v-if="!dataItem.id_stopwatches"
        v-b-tooltip.hover.top="'İşlem Başlat'"
        class="btn-icon"
        variant="success"
        pill
        @click="stopwatch"
      >
        <FeatherIcon icon="PlayIcon" />
      </b-button>
      <b-button
        v-else
        v-b-tooltip.hover.top="'İşlem Duraklat'"
        class="btn-icon"
        variant="info"
        pill
        @click="stopwatch"
      >
        <FeatherIcon icon="PauseIcon" />
      </b-button>
    </b-col>
    <b-col>
      <div class="d-flex justify-content-between">
        <div>
          <div class="font-weight-bold text-primary">
            {{ dataItem.title }}
          </div>
          <div class="font-small-3">
            {{ dataItem.text }}
          </div>
          <div class="font-small-2 text-warning">
            {{ dataItem.assigned }}
          </div>
        </div>
        <div class="font-small-2 text-muted text-right">
          <div v-if="dataItem.sdate">
            Başlangıç: {{ moment(dataItem.sdate).format('DD.MM.YYYY') }}
          </div>
          <div v-if="dataItem.deadline">
            Teslim: {{ moment(dataItem.deadline).format('DD.MM.YYYY') }}
          </div>
          <template v-if="dataItem.sdate && dataItem.deadline">
            <template v-if="['1','2','3'].includes(dataItem.id_task_statuses)">
              <div
                v-if="moment.duration(moment(dataItem.deadline).diff(moment(dataItem.sdate))).days() >= 0"
                class="text-info"
              >
                {{ moment.duration(moment(dataItem.deadline).diff(moment(dataItem.sdate))).days() }} gün kaldı
              </div>
              <div
                v-else
                class="text-danger"
              >
                Süre Doldu
              </div>
            </template>
          </template>
        </div>
      </div>
      <div class="font-weight-bolder border-top mt-1 pt-1 d-flex align-items-center justify-content-between">
        <div
          v-if="duration"
          class="text-success"
        >
          <div class="font-small-2 text-muted">
            İşlem Aktif
          </div>
          {{ duration }}
        </div>
        <div
          class="text-danger"
        >
          <div>
            <div class="font-small-2 text-muted">
              Toplam Süre
            </div>
            {{ moment.utc(moment.duration(dataItem.duration).asMilliseconds()).format('HH:mm:ss') }}
          </div>
        </div>
        <div
          v-if="dataItem.id_task_statuses === '5'"
          class="text-success"
        >
          {{ dataItem.task_status }}
        </div>
      </div>
    </b-col>
    <b-col
      v-if="['1','2','3'].includes(dataItem.id_task_statuses)"
      cols="12"
      md="auto"
    >
      <b-button
        v-b-tooltip.hover.top="'İşlemi Tamamla'"
        class="btn-icon"
        variant="danger"
        pill
        :disabled="!dataItem.stopwatches"
        @click="setCompleted"
      >
        <FeatherIcon icon="CheckIcon" />
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import {
  BButton, BCol, BRow, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'TaskLineItem',
  components: { BButton, BCol, BRow },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    dataItem: {
      type: Object,
      required: true,
    },
    dataIndex: {
      type: Number,
      required: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      duration: null,
      totalDuration: null,
    }
  },
  computed: {
    taskData() {
      return this.$store.getters['tasks/dataItem']
    },
  },
  mounted() {
    this.setDuration()
  },
  methods: {
    setDuration() {
      setInterval(() => {
        if (!this.dataItem.stopped && this.dataItem.started) {
          this.duration = this.moment.utc(this.moment.duration(this.moment().diff(this.dataItem.started)).asMilliseconds()).format('HH:mm:ss')
        } else {
          this.duration = null
        }
      }, 1000)
    },
    stopwatch() {
      this.$store.dispatch('taskLines/stopwatch', {
        id_task_lines: this.dataItem.id,
      })
        .then(response => {
          if (response) {
            this.taskData.lines[this.dataIndex].stopwatches = response.stopwatches
            this.taskData.lines[this.dataIndex].id_stopwatches = response.id_stopwatches
            this.taskData.lines[this.dataIndex].id_task_statuses = response.id_task_statuses
            this.taskData.lines[this.dataIndex].duration = response.duration
            this.taskData.lines[this.dataIndex].started = response.started
            this.taskData.lines[this.dataIndex].stopped = response.stopped
            this.taskData.lines[this.dataIndex].task_status = response.task_status
            this.totalDuration = response.duration
          }
        })
    },
    setCompleted() {
      this.$store.dispatch('taskLines/saveData', {
        id: this.dataItem.id,
        id_task_statuses: 5,
      })
        .then(response => {
          if (response) {
            this.refreshData()
          }
        })
    },
  },
}
</script>
