import { render, staticRenderFns } from "./CustomerHistory.vue?vue&type=template&id=d59dbc10&scoped=true&"
import script from "./CustomerHistory.vue?vue&type=script&lang=js&"
export * from "./CustomerHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d59dbc10",
  null
  
)

export default component.exports