<template>
  <validation-observer ref="simpleRules">
    <b-row>
      <b-col cols="8">
        <task-title />
      </b-col>
      <b-col cols="4">
        <user-assigned />
      </b-col>
      <b-col cols="12">
        <task-text />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <start-date />
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <dead-line />
      </b-col>
      <b-col cols="12">
        <b-button
          variant="danger"
          :disabled="submitStatus"
          @click="submitForm"
        >
          <FeatherIcon icon="SaveIcon" />
          Kaydet
        </b-button>
      </b-col>
    </b-row>
  </validation-observer>
</template>
<script>
import {
  BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import TaskTitle from '@/views/Admin/Tasks/elements/TaskLine/TaskTitle.vue'
import UserAssigned from '@/views/Admin/Tasks/elements/TaskLine/UserAssigned.vue'
import TaskText from '@/views/Admin/Tasks/elements/TaskLine/TaskText.vue'
import StartDate from '@/views/Admin/Tasks/elements/TaskLine/StartDate.vue'
import DeadLine from '@/views/Admin/Tasks/elements/TaskLine/DeadLine.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'TaskLine',
  components: {
    BRow,
    BCol,
    BButton,
    TaskTitle,
    UserAssigned,
    TaskText,
    StartDate,
    DeadLine,
    ValidationObserver,
  },
  props: {
    refreshData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['taskLines/dataItem']
    },
    saveData() {
      return this.$store.getters['taskLines/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('taskLines/RESET_DATA_ITEM')
    localize('tr')
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.dataItem.id_tasks = this.$route.params.id
          this.$store.dispatch('taskLines/saveData', this.dataItem)
            .then(response => {
              if (response.status) {
                this.$store.commit('taskLines/RESET_DATA_ITEM')
                this.refreshData()
              }
              this.submitStatus = false
              this.$refs.simpleRules.reset()
            })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
