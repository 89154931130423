<template>
  <b-card title="Müşteri Geçmişi">
    <b-alert
      show
      variant="info"
    >
      <div class="alert-body text-center">
        <FeatherIcon icon="InfoIcon" />
        Sayfa Hazırlanıyor...
      </div>
    </b-alert>
  </b-card>
</template>

<script>
import { BCard, BAlert } from 'bootstrap-vue'

export default {
  name: 'Customer',
  components: {
    BCard,
    BAlert,
  },
}
</script>

<style scoped>

</style>
